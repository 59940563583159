<template>
  <div :class="[$style.container]">
    <div :class="$style['image-container']">
      <SharedDiscount
        v-if="discount"
        :class="$style.discount"
        >{{ discount }}</SharedDiscount
      >
      <SharedAppCardImage
        :src="img"
        :class="$style.image"
        :responsive="true"
      />
      <div
        v-if="productPreviewName"
        :class="$style.product"
      >
        <img
          v-if="productPreviewIcon"
          :class="$style['product-icon']"
          :src="productPreviewIcon"
        />
        {{ productPreviewName }}
      </div>
    </div>
    <div :class="$style['app-name']">{{ app }}</div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  img: string | null;
  app: string;
  discount?: number | string;
  productPreviewName?: string | null;
  productPreviewIcon?: string | null;
}>();
const { img, app, discount } = toRefs(props);
</script>

<style lang="scss" module>
@use "sass:math";
.container {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: var(--spacing-5xl) var(--spacing-2xl);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);
  flex: 0 0;
  background: var(--app-action-cards-default);

  border-radius: var(--radius-8xl);
  font-size: 18px;
  letter-spacing: -0.09px;

  border-radius: 14.63415%;

  &:hover {
    background: linear-gradient(
        225.11deg,
        rgba(64, 64, 64, 0.4) 0%,
        rgba(132, 132, 132, 0.4) 53.74%,
        rgba(236, 236, 236, 0.4) 98.6%
      ),
      linear-gradient(
        0deg,
        var(--app-action-cards-hover),
        var(--app-action-cards-hover)
      );
  }
  &:active {
    background: linear-gradient(
        225.11deg,
        rgba(64, 64, 64, 0.4) 0%,
        rgba(132, 132, 132, 0.4) 53.74%,
        rgba(236, 236, 236, 0.4) 98.6%
      ),
      linear-gradient(
        0deg,
        var(--app-action-cards-hover),
        var(--app-action-cards-hover)
      );
  }
  &:disabled {
    background: var(--app-action-cards-disabled, rgba(76, 76, 76, 0.12));
    .image {
      opacity: 0.5;
    }
  }

  @media screen and (max-width: $md) {
    border-radius: var(--radius-7xl);
    font-size: 18px;
    letter-spacing: -0.09px;
    border-radius: 16.26016%;
  }
  @media screen and (max-width: $sm) {
    border-radius: var(--radius-5xl);
    font-size: 14px;
    letter-spacing: -0.07p;
    border-radius: 14.37126%;
  }
}

.discount {
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
}
.app-name {
  text-align: center;
}
.image-container {
  position: relative;
  .image {
    width: 100%;
  }
}
.product {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  height: 24px;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 2px;

  border-radius: var(--border-radius-radius-max);
  background: var(--general-transparent-dark-35);
  backdrop-filter: blur(5px);
  font-size: 12px;
  font-weight: 500;
  border-radius: var(--radius-max);
  &-icon {
    height: 18px;
  }
  @media screen and (max-width: $sm) {
    height: 10px;
    bottom: 6px;
    height: 18px;
    padding: 0px 6px 0px 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
}
</style>
