<template>
  <BaseSlide :class="$style.slide">
    <template #title>{{ t("home.swiper.second_slide.title") }}</template>
    <template #description>{{
      t("home.swiper.second_slide.description")
    }}</template>
    <template #controls>
      <SharedButtonSecondaryButton
        size="l"
        :class="$style['telegram-btn']"
        data-test="telegram-btn-mail-slider"
        @click="navigateTo(coreStore.tg, { external: true })"
      >
        <IconTg /> {{ t("home.telegram") }}
      </SharedButtonSecondaryButton>
    </template>
  </BaseSlide>
</template>

<script setup lang="ts">
import BaseSlide from "./BaseSlide.vue";

const { t } = useI18n();
const coreStore = useCoreStore();
</script>

<style module lang="scss">
.slide {
  background-image: url("~/assets/slides/main/second/desktop.png"),
    linear-gradient(355deg, #7dc8fd 0%, #0c8aff 106.55%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  svg {
    width: 16px;
  }
  path {
    fill: var(--general-background);
  }
  h1 {
    text-align: left;
    width: 65%;
    @media screen and (max-width: $md) {
      width: 90%;
    }
    @media screen and (max-width: 650px) {
      text-align: center;
      margin: 0 auto;
    }
  }
  p {
    top: 230px;
    text-align: left;
    max-height: 100px;
    @media screen and (max-width: 1350px) {
      top: 300px;
    }
    @media screen and (max-width: $md) {
      top: 196px;
    }
    @media screen and (max-width: 730px) {
      top: 250px;
    }
    @media screen and (max-width: 500px) {
      width: 70%;
    }
    @media screen and (max-width: 650px) {
      text-align: center;
      top: 200px;
    }
  }
  @media screen and (max-width: $md) {
    background-image: url("~/assets/slides/main/second/tablet.png"),
      linear-gradient(355deg, #7dc8fd 0%, #0c8aff 106.55%);
  }
  @media screen and (max-width: 650px) {
    background-image: url("~/assets/slides/main/second/mobile.png"),
      linear-gradient(355deg, #7dc8fd 0%, #0c8aff 106.55%);
  }
}
.telegram-btn {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
  background: var(--button-filled-default);
  color: var(--general-background);
  left: 40px;
  transition: 0.2s;
  &:hover {
    background: var(--button-filled-default);
    color: var(--general-background);
  }
  @media screen and (max-width: 650px) {
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 130%;
  }
}
</style>
