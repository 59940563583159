<template>
  <BaseSlide :class="$style.slide">
    <template #title>
      <span
        v-html="t('home.swiper.third_slide.title')"
        :class="$style['steam-header']"
      ></span>
    </template>
    <template #description>
      <span :class="$style['steam-sub-header']">
        {{ t("home.swiper.third_slide.description") }}
      </span>
    </template>
    <template #controls>
      <SharedButtonSecondaryButton
        v-if="steamCardID"
        size="l"
        :class="$style['steam-btn']"
        data-test="steam-btn-slider"
        @click="navigateTo(`/products/${steamCardID}`)"
      >
        {{ t("home.steam_banner_topup") }}
      </SharedButtonSecondaryButton>
    </template>
  </BaseSlide>
  <div :class="$style.ellips"></div>
  <div :class="$style['steam-logo']"></div>
</template>

<script setup lang="ts">
import BaseSlide from "./BaseSlide.vue";

const { t } = useI18n();
const gamesStore = useGamesStore();
const { steamCardID } = storeToRefs(gamesStore);
</script>

<style module lang="scss">
.ellips {
  position: absolute;
  top: 120%;
  right: 0;
  width: 79%;
  height: 503.263px;
  flex-shrink: 0;
  border-radius: 1129px;
  background: #1765ff;
  filter: blur(400px);
  @media screen and (max-width: 1100px) {
    width: 100%;
    top: 100%;
  }
  @media screen and (max-width: 750px) {
    top: 70%;
    filter: blur(300px);
  }
}

.steam-logo {
  position: absolute;
  top: 0;
  right: 10%;
  height: 100%;
  width: 100%;
  background-image: url("~/assets/slides/main/third/desktop.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;

  @media screen and (max-width: 1100px) {
    top: 15%;
    right: 0;
    background-image: url("~/assets/slides/main/third/tablet.png");
  }

  @media screen and (max-width: 750px) {
    width: 400px;
    height: 500px;
    background-image: url("~/assets/slides/main/third/mobile.png");
  }

  @media screen and (max-width: 650px) {
    top: 50%;
    right: 50%;
    transform: translate(50%, -30%);
    width: 283px;
    height: 299px;
  }
}

.steam-header {
  @media screen and (max-width: 650px) {
    font-size: 32px;
    line-height: 10%;
    letter-spacing: -0.64px;
  }
}
.steam-sub-header {
  top: 230px;
  text-align: left;
  max-height: 100px;
}

.steam-btn {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
  background: var(--button-filled-default);
  color: var(--general-background);
  left: 40px;
  transition: 0.2s;
  &:hover {
    background: var(--button-filled-default);
    color: var(--general-background);
  }
  @media screen and (max-width: 650px) {
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 130%;
  }
}
</style>
