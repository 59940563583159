<template>
  <img :class="[$style.img]" />
</template>

<style lang="scss" module>
.img {
  object-fit: cover;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  border-radius: var(--radius-6xl);
  max-width: 180px;
  width: 75%;
  aspect-ratio: 1/1;
  border-radius: 17.77778%;
  @media screen and (max-width: $xl) {
    border-radius: var(--radius-5xl);
    max-width: 142px;
    width: 75%;
    aspect-ratio: 1/1;
    border-radius: 16.90144%;
  }
  @media screen and (max-width: $md) {
    border-radius: var(--radius-xl);
    max-width: 86px;
    width: 85%;
    aspect-ratio: 1/1;
    border-radius: 18.60465%;
  }
}
</style>
