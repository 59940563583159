import { useApi } from "~/composables/useApi";
import type { Game } from "~/api_gen";

export const useGamesStore = defineStore("gamesStore", () => {
  /**
   * Данные по играм.
   */
  const games = ref<Game[]>([]);
  const count = ref(0);
  /**
   * Общее кол-во игр.
   */
  const total = ref(0);

  /**
   * Индикатор загрузки следующей части игр.
   */
  const loading = ref(false);
  /**
   * Кол-во загружаемых игр в одном запросе.
   */
  const limit = 16;

  const steamCardID = ref(0);

  async function fetchGames(page: number) {
    const data = await useApi().games.list({ offset: page * limit, limit });
    if (
      data &&
      "count" in data &&
      "next" in data &&
      "results" in data &&
      Array.isArray(data.results)
    ) {
      games.value = data.results;
      count.value = data.count;
      total.value = Math.ceil(data.count / limit);
      getSteamId();
    }
  }

  const getSteamId = () => {
    const steamCard = games.value.find((game) => game.title === "Steam");
    steamCardID.value = steamCard ? steamCard.id : 0;
  };

  return {
    games,
    count,
    total,
    loading,
    steamCardID,
    fetchGames,
  };
});
