<template>
  <Block :class="$style['main-games']">
    <section :class="$style['games-descr']">
      <MainBlockHeader
        :title="t('home.all_games')"
        :count="count"
      />
    </section>
    <section
      :class="$style['games-list']"
      data-test="games-list"
    >
      <NavLink
        v-for="game in games"
        :key="game.id"
        :to="`/products/${game.id}`"
        :data-test="`game-in-games-list-${game.id}`"
      >
        <AppCard
          :img="game.logo"
          :app="game.title"
          :class="$style['games-list_card']"
          :responsive="true"
          :discount="getDiscountString(game.discountPercent)"
          :product-preview-name="game.productPreviewName"
          :product-preview-icon="game.productPreviewIcon"
        />
      </NavLink>
    </section>
    <SharedPagination
      url="/"
      :page="currentPage"
      :total="total"
    ></SharedPagination>
  </Block>
</template>

<script setup lang="ts">
import Block from "~/components/Shared/Block/Block.vue";
import AppCard from "~/components/Shared/AppCard/index.vue";
import NavLink from "~/components/Shared/NavLink/NavLink.vue";
import MainBlockHeader from "~/components/Home/MainBlockHeader.vue";
import {
  codeFormEventTarget,
  ACTION,
} from "~/components/CodeForm/codeFormEventTarget";

let timer: ReturnType<typeof setTimeout> | undefined;
const { t } = useI18n();
const userStore = useUserStore();
const gamesStore = useGamesStore();
const { games, total, count } = storeToRefs(gamesStore);
const route = useRoute();
const currentPage = computed(() =>
  !isNaN(Number(route.params.page)) ? Number(route.params.page) : 0,
);

try {
  await gamesStore.fetchGames(currentPage.value - 1);
} catch (error) {
  /* empty */
}

watch(
  () => userStore.userIsAuthorized,
  async () => {
    if (isServer()) return;
    timer = setTimeout(
      () => gamesStore.fetchGames(currentPage.value - 1).catch(() => {}),
      100,
    );
  },
);

function getDiscountString(discount: number): string | undefined {
  return discount ? `${discount}%` : undefined;
}
if (currentPage.value > 1 && games.value.length === 0) {
  showError({ statusCode: 404 });
}

onBeforeMount(() =>
  codeFormEventTarget.addEventListener(ACTION, handleCodeFormEvent),
);

onBeforeUnmount(() => {
  clearTimeout(timer);
  codeFormEventTarget.removeEventListener(ACTION, handleCodeFormEvent);
});

function handleCodeFormEvent() {
  gamesStore.fetchGames(currentPage.value - 1);
}
</script>

<style module lang="scss">
.main-games {
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5xl);
  margin-top: var(--spacing-4xl);

  @media screen and (max-width: $xl) {
    margin-top: var(--spacing-2lg);
  }

  @media screen and (max-width: $sm) {
    margin-top: var(--spacing-xs);
    gap: var(--spacing-4xl);
  }
}

.games-descr {
  display: flex;
  justify-content: space-between;

  &_title {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);

    &_count {
      color: var(--general-transparent-light-35);
    }
  }
}

.sort-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
}

.games-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: var(--spacing-2lg);
  grid-row-gap: var(--spacing-2lg);
  width: 100%;

  &_card {
    width: 100%;
    min-width: 100%;
    aspect-ratio: 1/0.94;
  }

  @media screen and (max-width: $xl) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: var(--spacing-xs);
    grid-row-gap: var(--spacing-xs);
    &_card {
      aspect-ratio: auto;
      height: 100%;
    }
  }

  @media screen and (max-width: $md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.games-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4xl);

  &_title {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.09px;
  }

  @media screen and (max-width: $sm) {
    &_title {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.07px;
    }

    button {
      width: 100%;
    }
  }
}

.show-more-btn {
  padding-right: var(--spacing-11xl);
  padding-left: var(--spacing-11xl);
  @media screen and (max-width: $sm) {
    width: 100%;
    height: 54px;
    padding-right: var(--spacing-7xl);
    padding-left: var(--spacing-7xl);
  }
}
</style>
