<template>
  <div
    v-if="total > 1"
    :class="$style.pagination"
  >
    <div :class="$style.pagination_pages">
      <template v-if="total > PAGES_TO_SHOW && page >= CENTER">
        <SharedNavLink :to="getLink(1)">
          <SecondaryButton :class="$style.pagination_button">
            1
          </SecondaryButton>
        </SharedNavLink>
        <SecondaryButton
          :class="[$style.pagination_button, $style.pagination_dots]"
        >
          ...
        </SecondaryButton>
      </template>
      <template
        v-for="item in pages"
        :key="item"
      >
        <SharedNavLink
          v-if="page !== item"
          :to="getLink(item)"
        >
          <SecondaryButton :class="$style.pagination_button">
            {{ item }}
          </SecondaryButton>
        </SharedNavLink>
        <PrimaryButton
          v-else
          :class="$style.pagination_button"
        >
          {{ item }}
        </PrimaryButton>
      </template>
      <template v-if="total > PAGES_TO_SHOW && page < total - CENTER">
        <SecondaryButton
          :class="[$style.pagination_button, $style.pagination_dots]"
        >
          ...
        </SecondaryButton>
        <SharedNavLink
          v-if="total > PAGES_TO_SHOW && page < total - CENTER"
          :to="getLink(total)"
        >
          <SecondaryButton :class="$style.pagination_button">
            {{ total }}
          </SecondaryButton>
        </SharedNavLink>
      </template>
    </div>
    <SharedNavLink :to="getLink(page + 1)">
      <SharedButtonPrimaryButton v-if="props.page < total">
        {{ $t("pagination.next") }} <IconChevronRight height="14" />
      </SharedButtonPrimaryButton>
    </SharedNavLink>
  </div>
</template>
<script setup lang="ts">
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
import PrimaryButton from "~/components/Shared/Button/PrimaryButton.vue";
import { normalize } from "~/utils/url/normalize";

const PAGES_TO_SHOW = 5;
const CENTER = 3;

const props = defineProps<{ total: number; page: number; url: string }>();

const pages = computed(() => {
  const farFromLeftSide = props.page >= CENTER;
  const farFromRightSide = props.page <= props.total - CENTER;
  const pagesLength = props.total < PAGES_TO_SHOW ? props.total : PAGES_TO_SHOW;
  if (!farFromLeftSide) {
    return new Array(pagesLength).fill(0).map((_, i) => i + 1);
  }
  if (!farFromRightSide) {
    return new Array(pagesLength)
      .fill(0)
      .map((_, i) => props.total - (pagesLength - i) + 1);
  }
  return new Array(pagesLength).fill(0).map((_, i) => props.page + i - 1);
});

const getLink = (page: number) => normalize(`${props.url}/${page}`);
</script>
<style module lang="scss">
.pagination {
  display: flex;
  gap: var(--spacing-xs);
  width: 100%;
  justify-content: center;
  &_pages {
    display: flex;
    gap: var(--spacing-xs);
    justify-content: center;
  }
  &_button {
    width: 44px;
    height: 44px;
    @media screen and (max-width: $sm) {
      width: 36px;
      height: 36px;
      flex-direction: column;
    }
  }
  &_dots {
    cursor: default;
    &:hover {
      background: var(--button-secondary-default);
      color: var(--general-transparent-light-100);
    }
    &:active {
      background: var(--button-secondary-default);
      color: var(--general-transparent-light-100);
    }
  }
  @media screen and (max-width: $sm) {
    flex-direction: column;
    gap: var(--spacing-4xl);
  }
}
</style>
