<template>
  <div :class="$style.base_slide">
    <div :class="$style['base_slide__title']">
      <SharedHeadingHeading1>
        <slot name="title" />
      </SharedHeadingHeading1>
      <p>
        <slot name="description" />
      </p>
    </div>
    <slot name="picture"></slot>
    <div :class="$style.base_slide_controls">
      <slot name="controls"></slot>
    </div>
  </div>
</template>
<style module lang="scss">
.base_slide {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;

  &__title {
    margin: 0 40px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2lg);

    @media screen and (max-width: 650px) {
      align-items: center;
      gap: var(--spacing-md);
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.64px;

      h1,
      p {
        text-align: center;
      }
    }

    h1 {
      padding-top: 60px;
    }

    p {
      width: 100%;
      font-size: 1.125rem;
      font-weight: normal;
      @media screen and (max-width: $md) {
        font-size: 1rem;
      }
      @media screen and (max-width: 650px) {
        font-size: 0.875rem;
      }
    }
  }

  &_controls {
    position: absolute;
    bottom: 40px;
    display: flex;
    gap: var(--spacing-2lg);
    z-index: 1;
    @media screen and (max-width: $md) {
      bottom: 60px;
    }

    @media screen and (max-width: 650px) {
      bottom: 78px;
    }
  }

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h1 {
      padding-top: 40px;
    }
  }
}
</style>
